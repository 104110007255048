import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useCopyToClipboard, useEffectOnce, useStateList } from 'react-use';
import {
  IoRefresh,
  IoFilter,
  IoCloseSharp,
  IoChevronDown,
  IoChevronUp,
  IoAlertCircleOutline,
  IoTrash,
} from 'react-icons/io5';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

// components
import { Row, Col } from 'components/generic/Layout';
import StaffNavBar from 'components/nav/StaffNavBar';
import Select from 'components/generic/Select';
import BooksDropdown from 'components/BooksDropdown';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ModalWrapper from 'components/generic/ModalWrapper';
import DateRange from 'components/generic/DateRange';
import DisplayToggle from 'components/generic/DisplayToggle';
import ConfirmDelete from 'components/modals/ConfirmDelete';
import { Thead, Th, Tbody } from 'components/generic/Tables';

import { STATE_OPTIONS, humanDate } from 'utils';

// actions
import { getAllBooks } from 'actions';

const Table = styled.table`
  width: 100%;
`;

export default function AffiliateEarningDashboard(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;
  const [selectedObj, setSelectedObj] = useState();
  const [filters, setFilters] = useState({
    books_ids: [],
    regions: [],
    group_ids: [],
    ref_code: '',
    internal_code: '',
    from_date: moment().startOf('month').toISOString(),
    to_date: moment().toISOString(),
    only_money_makers: true,
    reports: true,
    sort: JSON.stringify(['-registrations']),
  });

  const [expandLeft, setExpandLeft] = useState(true);
  const [bookItems, setBookItems] = useState([]);
  const [groupItems, setGroupItems] = useState([]);
  const getEarnings = useInfiniteQuery({
    keepPreviousData: true,
    queryKey: [{ endpoint: 'aff-earnings-dashboard', urlParams: filters }],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
    retry: false,
  });

  const getSummary = useQuery({
    queryKey: [
      {
        endpoint: 'aff-earnings-dashboard',
        urlParams: { ...filters, summary: true },
      },
    ],
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffectOnce(() => {
    dispatch(getAllBooks());
  });

  const tableData = useMemo(() => {
    let currData = {};
    if (expandLeft || !!selectedObj) {
      currData.headings = [
        'ID',
        'Period',
        'Book',
        'Region',
        'Group',
        'Referral Code',
        'CPA Count',
        'Registrations',
        'Clicks',
      ];
      currData.objKeys = [
        'id',
        'data_period',
        'book',
        'state',
        'group__name',
        'referral_code__code',
        'cpa_commission_count',
        'registrations',
        'clicks',
      ];
    } else {
      currData.headings = [
        'ID',
        'Period',
        'Book',
        'Region',
        'Group',
        'Referral Code',
        'Currency',
        'Deposit Amount',
        'CPA Count',
      ];
      currData.objKeys = [
        'id',
        'data_period',
        'book',
        'state',
        'group__name',
        'referral_code__code',
        'currency',
        'converted_deposits',
        'cpa_commission_count',
      ];
    }
    return currData;
  }, [expandLeft, selectedObj]);

  const pages = getEarnings?.data?.pages;
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [copySuccess, setCopySuccess] = useState(false);
  return (
    <>
      <StaffNavBar />
      <Row
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'flex-start',
        }}
      >
        {!!selectedObj ? (
          <Panel obj={selectedObj} clearPanel={() => setSelectedObj(null)} />
        ) : (
          <FilterTab
            containerStyle={{ width: '25%' }}
            filters={filters}
            setFilters={filt => setFilters(filt)}
            getEarnings={getEarnings}
            expand={expandLeft}
            setExpand={setExpandLeft}
            persistItems={{
              groups: {
                update: items => setGroupItems(items),
                items: groupItems,
              },
              books: { update: items => setBookItems(items), items: bookItems },
            }}
          />
        )}
        <Col
          style={{
            flex: 3,
            justifyContent: 'flex-start',
          }}
        >
          <Row
            style={{
              width: '100%',
              backgroundColor: 'var(--color-bg)',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid var(--color-fg)',
              gap: '1rem',
              padding: '0 var(--space-sm)',
            }}
          >
            <DisplayToggle
              style={{
                width: '100%',
                flexWrap: 'nowrap',
                flex: 1,
              }}
              options={['Show Empty CPA', 'Hide Empty CPA']}
              active={
                filters.only_money_makers ? 'Hide Empty CPA' : 'Show Empty CPA'
              }
              onPress={opt => {
                setFilters({
                  ...filters,
                  only_money_makers: opt === 'Hide Empty CPA',
                });
                setFilters({
                  ...filters,
                  only_money_makers: opt === 'Hide Empty CPA',
                });
              }}
            />
            <DateRange
              containerStyle={{
                flex: 2,
              }}
              getItemsInRange={(date1, date2) => {
                if (date1 === 'ALL_TIME' || date2 === 'ALL_TIME') {
                  setFilters({ ...filters, from_date: date1, to_date: date2 });
                  return;
                }
                let from_date = '';
                let to_date = '';
                if (date1 !== undefined) {
                  date1 = moment(new Date(date1));
                  from_date = date1
                    .set('hour', 0)
                    .set('minute', 0)
                    .toISOString();
                }
                if (date2 !== undefined) {
                  date2 = moment(new Date(date2));
                  to_date = date2
                    .set('hour', 23)
                    .set('minute', 59)
                    .toISOString();
                }
                setFilters({
                  ...filters,
                  from_date: from_date,
                  to_date: to_date,
                });
              }}
              defaultStartDate={
                filters?.date_after
                  ? filters?.date_after === 'ALL_TIME'
                    ? null
                    : filters?.date_after
                  : moment().startOf('month').toISOString()
              }
              defaultEndDate={
                filters?.date_before
                  ? filters?.date_before === 'ALL_TIME'
                    ? null
                    : filters?.date_before
                  : moment().toISOString()
              }
              allTimeReturnValue={'ALL_TIME'}
              placeholder={
                filters?.from_date === 'ALL_TIME' &&
                  filters.to_date === 'ALL_TIME'
                  ? 'All Time'
                  : 'Month to Date'
              }
            />
            <Col>
              <AuthButton
                onPress={() => {
                  let csv = '';
                  for (const p of pages) {
                    for (const r of p.results) {
                      const vals = Object.values(r);
                      for (let i = 0; i < vals.length; i++) {
                        csv += vals[i];
                        if (i !== vals.length - 1) {
                          csv += '\t';
                        }
                      }
                      csv += '\n';
                    }
                    copyToClipboard(csv);
                  }
                }}
                containerStyle={{ flex: 0.5 }}
              >
                Copy Table Data
              </AuthButton>
              <small>
                Use <b>Ctrl+Shift+v</b> to paste into spreadsheet
              </small>
            </Col>
          </Row>
          <Col
            style={{
              flex: 7,
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <Row
              style={{
                width: '100%',
                flex: 0,
              }}
            >
              {!!getEarnings.isLoading && (
                <Col
                  style={{
                    width: '100%',
                    borderBotton: '1px solid var(--color-text-light)',
                    marginBottom: 'var(--space-md)',
                  }}
                >
                  <ActivityIndicator size={2} />
                </Col>
              )}
              {!getEarnings.isLoading && getEarnings.error && (
                <Row
                  style={{
                    height: '256px',
                    width: '90%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px dashed var(--color-complement)',
                    borderRadius: 12,
                    margin: 'var(--space-sm)',
                    padding: 'var(--space-md) 0',
                  }}
                >
                  <Col>
                    <IoAlertCircleOutline
                      size={64}
                      color={'var(--color-complement)'}
                    />
                    <h6>{getEarnings.error.message}</h6>
                  </Col>
                </Row>
              )}
              {!getSummary.isLoading &&
                getSummary.isSuccess &&
                !!getSummary.data &&
                getSummary.data.map((item, i) =>
                  ['Clicks', 'CPA Count', 'Registrations'].includes(
                    item.title
                  ) ? (
                    <SummaryEntry key={`summary-entry-${i}`} entry={item} />
                  ) : null
                )}
            </Row>
            {!getEarnings.isLoading && !!pages && (
              <RowTable
                modelName={'Earnings'}
                headings={tableData.headings}
                pages={pages}
                objKeys={tableData.objKeys}
                sortKeys={{
                  Period: 'data_period',
                  Book: 'book__name',
                  Region: 'state',
                  Group: 'group__name',
                  'Referral Code': 'referral_code__code',
                  'CPA Count': 'cpa_commission_count',
                  CPA: 'cpa_commission',
                  'Rev Share': 'rev_share',
                  data_period: 'Period',
                  book__name: 'Book',
                  state: 'Region',
                  group__name: 'Group',
                  referral_code__code: 'Referral Code',
                  cpa_commission_count: 'CPA Count',
                  cpa_commission: 'CPA',
                  rev_share: 'Rev Share',
                  // extras
                  'Deposit Amount': 'deposits',
                  deposits: 'Deposit Amount',
                  Registrations: 'registrations',
                  registrations: 'Registrations',
                }}
                onSort={sorts => {
                  setFilters({ ...filters, sort: sorts });
                }}
                sort={filters.sort}
                formatText={{
                  data_period: humanDate,
                  book: (book_id, obj) =>
                    allBooksMap && allBooksMap[book_id] ? (
                      <>
                        <img
                          src={allBooksMap[book_id].logo}
                          alt={allBooksMap[book_id].name}
                          width={22}
                          style={{
                            borderRadius: '2px',
                            verticalAlign: 'middle',
                          }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>
                          {allBooksMap[book_id].name}
                        </span>
                      </>
                    ) : (
                      book_id
                    ),
                  converted_cpa: t =>
                    t !== undefined && t !== null && `$${t.toLocaleString()}`,
                  converted_deposits: t =>
                    t !== undefined && t !== null && `$${t.toLocaleString()}`,
                  converted_ngr: t =>
                    t !== undefined && t !== null && `$${t.toLocaleString()}`,
                  converted_rev_share: t =>
                    t !== undefined && t !== null && `$${t.toLocaleString()}`,
                }}
                queryObj={getEarnings}
                selectedObj={selectedObj}
                setSelectedObj={val => setSelectedObj(val)}
                isLoadingNext={getEarnings.isFetchingNextPage}
              />
            )}
          </Col>
        </Col>
      </Row>
    </>
  );
}

function Panel(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;
  const { obj, clearPanel } = props;
  const formatText = {
    book: b => allBooksMap[b].name,
    data_period: humanDate,
  };
  return (
    <Col
      style={{
        flex: 1,
        padding: '0 var(--space-md)',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRight: '1px solid var(--color-fg)',
      }}
    >
      <Col
        style={{
          flex: 1,
          position: 'fixed',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '0 var(--space-xs)',
          overflowY: 'scroll',
          width: '25%',
        }}
      >
        <Row
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderBottom: '1px solid var(--color-fg)',
          }}
        >
          <IconButton iconName={IoCloseSharp} onPress={() => clearPanel()} />
          <h6>Affiliate Earning ({obj.id})</h6>
        </Row>
        {Object.entries(obj).map(([key, value], _key) => (
          <SingleField
            key={_key}
            dataKey={key}
            value={!!formatText[key] ? formatText[key](value) : value}
          />
        ))}
        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            margin: 'var(--space-sm) 0',
          }}
        >
          <small style={{ color: 'var(--color-text)' }}>
            <i>Only showing non-null values</i>
          </small>
        </Row>
      </Col>
    </Col>
  );
}

function SingleField(props) {
  const { dataKey, value } = props;
  if (value === null || value === undefined) {
    return <></>;
  }
  return (
    <Row
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <p style={{ margin: 0 }}>{dataKey.toString().split('_').join(' ')}:</p>
      <p style={{ margin: 0 }}>{value}</p>
    </Row>
  );
}

// this is used in EmailReports as well.
export function RowTable(props) {
  const {
    modelName,
    headings,
    pages,
    onSort,
    sort,
    sortKeys,
    objKeys,
    formatText,
    linkTo,
    queryObj,
    selectedObj,
    setSelectedObj,
    isLoadingNext,
    deleteBaseEndpoint,
  } = props;

  const sortDict = useMemo(() => {
    if (!sortKeys) return {};
    const newArr = sort && sortKeys ? JSON.parse(sort) : [];
    let newDict = {};
    for (const opt of newArr) {
      if (opt !== null) {
        newDict[sortKeys[opt.replace('-', '')]] = opt;
      }
    }
    return newDict;
  }, [sort, sortKeys]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteObj, setDeleteObj] = useState(null);

  const queryClient = useQueryClient();
  const deleteMut = useMutation({
    mutationKey: deleteBaseEndpoint,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'email-affiliate-reports',
          },
        ],
      });
      setShowDeleteModal(false);
    },
  });

  if (pages[0].results.length === 0) {
    return (
      <Col style={{ justifyContent: 'center' }}>
        <p style={{ color: 'var(--color-complement)' }}>
          No row data matches these filters
        </p>
      </Col>
    );
  }
  return (
    <>
      <ModalWrapper
        modalIsOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onRequestClose={() => setShowDeleteModal(false)}
        title="Confirm Delete"
        modalSize="small"
      >
        <ConfirmDelete
          onDelete={() => {
            deleteMut.mutate({
              endpoint: `${deleteBaseEndpoint}/${deleteObj.id}`,
              method: 'DELETE',
            });
          }}
          dismissModal={() => setShowDeleteModal(false)}
          isDeleting={deleteMut.isLoading}
        >
          Are you sure you want to delete this {modelName}?{' '}
          {props.extraDeleteText}
        </ConfirmDelete>
        {deleteMut.isError && (
          <p style={{ color: 'var(--color-danger)', textAlign: 'center' }}>
            {deleteMut.error?.message}
          </p>
        )}
      </ModalWrapper>
      <Col
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'nowrap',
          width: '100%',
        }}
      >
        <Table cellSpacing={0}>
          <Thead>
            <tr>
              {headings.map(head => (
                <Th
                  style={
                    head === ''
                      ? { width: '128px' }
                      : { padding: 'var(--space-sm)' }
                  }
                  key={`aff-earning-table-head-${head}`}
                >
                  {head !== '' && (
                    <AuthButton
                      containerStyle={{
                        margin: 0,
                        borderRadius: '4px',
                      }}
                      btnStyle={{
                        padding: 'var(--space-xs) 0',
                        borderRadius: '4px',
                        backgroundColor: 'transparent',
                      }}
                      textStyle={{
                        textAlign: 'left',
                        color: 'var(--color-text)',
                      }}
                      colorTheme="text"
                      btnTheme="borderless"
                      rightIcon={
                        sortDict[head]
                          ? sortDict[head].includes('-')
                            ? IoChevronDown
                            : IoChevronUp
                          : null
                      }
                      rightIconSize={22}
                      title={
                        sortKeys && sortKeys[head]
                          ? `Sort by ${head} ${sortDict[head]?.includes('-')
                            ? 'Ascending'
                            : 'Descending'
                          }`
                          : undefined
                      }
                      disabled={!sortKeys || !sortKeys[head]}
                      onPress={
                        sortKeys && sortKeys[head]
                          ? () => {
                            let newSortDict = { ...sortDict };
                            if (!sortDict[head]) {
                              newSortDict[head] = sortKeys[head];
                              onSort(
                                JSON.stringify(Object.values(newSortDict))
                              );
                            } else if (
                              newSortDict[head] &&
                              !sortDict[head].includes('-')
                            ) {
                              newSortDict[head] = `-${sortKeys[head]}`;
                              onSort(
                                JSON.stringify(Object.values(newSortDict))
                              );
                            } else {
                              delete newSortDict[head];
                              onSort(
                                JSON.stringify(Object.values(newSortDict))
                              );
                            }
                          }
                          : null
                      }
                    >
                      {head.toUpperCase()}
                    </AuthButton>
                  )}
                </Th>
              ))}
            </tr>
          </Thead>
          <Tbody>
            {!!pages &&
              pages.map((page, i) => (
                <React.Fragment key={`aff-earning-base-page-${i}`}>
                  {page.results.map(row => (
                    <tr
                      style={
                        selectedObj?.id === row.id
                          ? { backgroundColor: 'var(--color-text-light)' }
                          : {}
                      }
                      key={`aff-earning-tr-${row.id}`}
                    >
                      {objKeys.map(k => {
                        let t = k.includes('.')
                          ? row[k.split('.')[0]][k.split('.')[1]]
                          : row[k];
                        if (formatText && formatText[k]) {
                          t = formatText[k](t, row);
                        }
                        t = t || '-';
                        return (
                          <td
                            key={`aff-earning-key-td-${k}`}
                            onClick={() => {
                              setSelectedObj(row);
                            }}
                          >
                            {linkTo && linkTo[k] ? (
                              <Link
                                title={`Go to betstamp.app${linkTo[k](row[k])}`}
                                style={{
                                  textDecoration: 'none',
                                  color: 'var(--color-primary)',
                                }}
                                to={linkTo[k](row[k])}
                              >
                                {t}
                              </Link>
                            ) : (
                              t
                            )}
                          </td>
                        );
                      })}
                      {deleteBaseEndpoint && (
                        <td>
                          <IconButton
                            btnStyle={{ padding: 'var(--space-xs)' }}
                            iconName={IoTrash}
                            colorTheme="danger"
                            onPress={() => {
                              setShowDeleteModal(true);
                              setDeleteObj(row);
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
          </Tbody>
        </Table>
        <Row
          style={{
            width: '100%',
            padding: 'var(--space-sm)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i style={{ textAlign: 'left', padding: '0 var(--space-xs)' }}>
            {!!pages && pages.length > 0 && pages[0] && (
              <>
                {pages[0]?.count} Total {modelName}
              </>
            )}
          </i>
          {queryObj.hasNextPage && (
            <AuthButton
              containerStyle={{
                margin: 0,
              }}
              btnTheme="borderless"
              onPress={() => queryObj.fetchNextPage()}
              isLoading={isLoadingNext}
            >
              Load More
            </AuthButton>
          )}
          {!!pages && pages?.length > 0 && pages[0] && (
            <>
              {pages?.length} page
              {pages?.length > 1 ? 's' : ''} loaded
            </>
          )}
        </Row>
      </Col>
    </>
  );
}

export function SummaryEntry(props) {
  const { entry } = props;
  const { title, value, type, symbol, disclaimer } = entry;

  const val = (Math.round(value * 100) / 100).toLocaleString();
  return (
    <Col
      style={{
        flexBasis: '30%',
        flexWrap: 'nowrap',
        border: '2px solid var(--color-fg)',
        borderRadius: 'var(--std-border-radius)',
        padding: 'var(--space-sm) 0',
        justifyContent: 'center',
        margin: 'var(--space-xxs)',
        ...props.containerStyle,
      }}
    >
      <h6 style={{ flex: 1, margin: 0 }}>{title}</h6>
      <h5
        style={{
          flex: 1.5,
          margin: 0,
          color:
            type === 'currency'
              ? parseInt(value) > 0
                ? 'var(--color-success)'
                : parseInt(value) < 0
                  ? 'var(--color-danger)'
                  : undefined
              : undefined,
        }}
      >
        {!!symbol && symbol}
        <strong>{val}</strong>
      </h5>
      {!!disclaimer && (
        <small
          style={{
            margin: 'var(--space-xxs) 0',
            textAlign: 'center',
            fontSize: 'var(--text-xs)',
          }}
        >
          {disclaimer}
        </small>
      )}
    </Col>
  );
}

function FilterTab(props) {
  const { filters, setFilters, getEarnings, expand, setExpand } = props;
  const [showBooksAvailable, setShowBooksAvailable] = useState(false);
  const [showFieldsAvailable, setShowFieldsAvailable] = useState();
  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [{ endpoint: `marketing-groups` }],
    retry: false,
  });

  const fetchBooksAvailable = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [{ endpoint: 'affiliate_earnings/details' }],
    retry: false,
  });

  if (!expand) {
    return (
      <Col
        style={{
          height: '100%',
          maxWidth: '48px',
          justifyContent: 'flex-start',
          borderRight: '1px solid var(--color-fg)',
        }}
      >
        <Col
          style={{
            position: 'fixed',
          }}
        >
          <IconButton iconName={IoFilter} onPress={() => setExpand(!expand)} />
          <IconButton
            onPress={() => {
              getEarnings.refetch();
            }}
            iconName={IoRefresh}
          />
        </Col>
      </Col>
    );
  } else {
    return (
      <Col
        style={{
          flex: 1,
          padding: '0 var(--space-md)',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderRight: '1px solid var(--color-fg)',
        }}
      >
        <ModalWrapper
          modalIsOpen={showBooksAvailable}
          onClose={() => setShowBooksAvailable(false)}
          onRequestClose={() => setShowBooksAvailable(false)}
          title="Books Available"
          modalSize="medium"
        >
          <Row
            style={{
              width: '100%',
            }}
          >
            <Row
              style={{
                flex: 2,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              {!!fetchBooksAvailable.data &&
                fetchBooksAvailable.data.books_available.map((b, i) => (
                  <BookInfo
                    key={`filtertab-bookinfo-${b?.id}-${i}`}
                    bookInfo={b}
                    setInfo={info => setShowFieldsAvailable(info)}
                  />
                ))}
            </Row>
            {!!showFieldsAvailable && (
              <Col
                style={{
                  flex: 1,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  padding: '0 var(--space-sm)',
                }}
              >
                <Col
                  style={{
                    position: 'fixed',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <h6>Fields available for this book</h6>
                  {showFieldsAvailable.map(f => (
                    <p style={{ margin: 0 }}>{f}</p>
                  ))}
                </Col>
              </Col>
            )}
          </Row>
        </ModalWrapper>
        <Col
          style={{
            flex: 1,
            position: 'fixed',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '0 var(--space-xs)',
            ...props.containerStyle,
          }}
        >
          <Row
            style={{
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderBottom: '1px solid var(--color-fg)',
            }}
          >
            <IconButton
              iconName={IoCloseSharp}
              onPress={() => setExpand(!expand)}
            />
            <AuthButton onPress={() => setShowBooksAvailable(true)}>
              View Available Books
            </AuthButton>
            <IconButton
              onPress={() => {
                getEarnings.refetch();
              }}
              iconName={IoRefresh}
            />
          </Row>
          <Col
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <BooksDropdown
              admin
              isMulti
              label="Books"
              placeholder="Any"
              onChange={opt => {
                let newBookItems = [];
                opt.map(item => newBookItems.push(item));
                props.persistItems?.books?.update(newBookItems);
                setFilters({
                  ...filters,
                  book_ids: newBookItems.map(item => item.value),
                });
              }}
              defaultValue={props.persistItems?.books.items}
            />
            <Select
              isMulti
              label="Regions"
              options={[
                { label: 'ROC', value: 'ROC' },
                { label: 'Canada', value: 'Canada' },
                { label: 'USA', value: 'USA' },
                ...STATE_OPTIONS.map(s => ({ label: s, value: s })),
              ]}
              placeholder="Any"
              onChange={opt => {
                let newRegions = [];
                opt.map(item => newRegions.push(item.value));
                setFilters({ ...filters, regions: newRegions });
              }}
              defaultValue={filters?.regions.map(r => ({
                label: r,
                value: r,
              }))}
            />
            {fetchMarketingGroups.isSuccess && (
              <Select
                isMulti
                label="Group:"
                options={fetchMarketingGroups.data.map(g => ({
                  value: g.id,
                  label: g.name,
                }))}
                onChange={opt => {
                  let newGroups = [];
                  let newGroupItems = [];
                  opt.map(item => {
                    newGroups.push(item.value);
                    newGroupItems.push(item);
                  });
                  props.persistItems?.groups?.update(newGroupItems);
                  setFilters({
                    ...filters,
                    group_ids: newGroups,
                  });
                }}
                defaultValue={props.persistItems?.groups?.items}
              />
            )}
            <AuthTextInput
              label="Referral Code (exact)"
              placeholder="Any"
              textTransform="uppercase"
              type="text"
              onChangeText={t => setFilters({ ...filters, ref_code: t })}
              defaultValue={filters?.ref_code}
            />
            <AuthTextInput
              label="Internal Code (exact)"
              placeholder="Any"
              type="text"
              onChangeText={t => setFilters({ ...filters, internal_code: t })}
              defaultValue={filters?.internal_code}
            />
          </Col>
        </Col>
      </Col>
    );
  }
}

function BookInfo(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;
  const { bookInfo, setInfo } = props;

  if (!allBooksMap) return;
  return (
    <AuthButton
      btnTheme="borderless"
      onPress={() => setInfo(bookInfo.fields_available)}
    >
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'nowrap',
          margin: 'var(--space-xs)',
        }}
      >
        <img
          width={32}
          height={32}
          src={allBooksMap[bookInfo.book__id]?.logo}
          alt={allBooksMap[bookInfo.book__id]?.name}
          style={{
            margin: '0 var(--space-xxs)',
            borderRadius: 'var(--std-border-radius)',
          }}
        />
        <p style={{ whiteSpace: 'nowrap' }}>
          {allBooksMap[bookInfo.book__id]?.name}
        </p>
      </Row>
    </AuthButton>
  );
}
